const mapValues_ = require('lodash/mapValues')

const { assertDatasetTypeIsRouter } = require('./datasetApiAssertions')

const routerDatasetApiCreator = ({
  datasetType,
  siblingDynamicPageUrlGetter
}) => {
  const routerDatasetApi = {
    async getNextDynamicPage() {
      return siblingDynamicPageUrlGetter != null
        ? siblingDynamicPageUrlGetter.getNextDynamicPageUrl()
        : null
    },

    async getPreviousDynamicPage() {
      return siblingDynamicPageUrlGetter
        ? siblingDynamicPageUrlGetter.getPreviousDynamicPageUrl()
        : null
    }
  }
  return mapValues_(routerDatasetApi, (fn, functionName) => (...args) => {
    assertDatasetTypeIsRouter(datasetType, functionName)
    return fn(...args)
  })
}

module.exports = routerDatasetApiCreator
