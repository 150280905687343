'use strict'
const {
  READ_WRITE,
  READ
} = require('@wix/wix-data-client-common/src/dataset-configuration/readWriteModes')
const isCompConfiguredToReadData = require('../helpers/isCompConfiguredToReadData')
const isForm = require('../helpers/isForm')
const { isEnvEditor } = require('../helpers/viewMode')

const {
  getReadWriteMode,
  getCollectionName,
  selectCurrentRecord
} = require('../dataset-controller/rootReducer')

const reportDatasetActiveOnPage = (
  biLogger,
  state,
  connections,
  datasetType,
  isScoped,
  datasetId,
  wixSdk
) => {
  if (isScoped) {
    return
  }
  const collectionName = getCollectionName(state)
  if (!collectionName) {
    return
  }

  const readWriteMode = getReadWriteMode(state)
  const someRecordLoaded = !!selectCurrentRecord(state)

  if (
    someRecordLoaded &&
    [READ_WRITE, READ].includes(readWriteMode) &&
    connections.find(({ role, config }) =>
      isCompConfiguredToReadData(role, config)
    )
  ) {
    biLogger({
      evid: isEnvEditor(wixSdk.window.viewMode) ? 153 : 152,
      ds_id: datasetId,
      ds_type: datasetType,
      mode: readWriteMode,
      collection_name: collectionName,
      viewmode: wixSdk.window.viewMode,
      page_url: wixSdk.location.url.split('?')[0]
    })
  }

  if (isForm(getReadWriteMode(state), connections)) {
    biLogger({
      evid: isEnvEditor(wixSdk.window.viewMode) ? 157 : 156,
      ds_id: datasetId,
      ds_type: datasetType,
      mode: readWriteMode,
      collection_name: collectionName,
      viewmode: wixSdk.window.viewMode,
      page_url: wixSdk.location.url.split('?')[0]
    })
  }
}

module.exports.reportDatasetActiveOnPage = reportDatasetActiveOnPage
