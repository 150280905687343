'use strict'

const { UserError } = require('../logger')
const ERROR_TYPE = 'DatasetError'

class DatasetError extends UserError {
  constructor(code, message) {
    super(message)
    this.name = ERROR_TYPE
    this.code = code
  }
}
module.exports = DatasetError
