'use strict'

const forEach_ = require('lodash/forEach')

module.exports = connectionConfig => {
  const { properties, events, behaviors } = connectionConfig
  const bindingDescription = {}

  forEach_(properties, ({ fieldName }, propName) => {
    bindingDescription[propName] = fieldName
  })
  forEach_(events, ({ action }, eventName) => {
    bindingDescription[eventName] = action
  })
  forEach_(behaviors, ({ type: behavior }) => {
    bindingDescription.text = behavior
  })

  return bindingDescription
}
