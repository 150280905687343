'use strict'

const { addComponentEventListener } = require('./helpers')
const baseAdapter = require('./baseAdapter')
const {
  getComponentValueAccessorName,
  getComponentDefaultValue
} = require('../../helpers/componentValueUtils')

module.exports = ({ applicationCodeZone }) => {
  return {
    ...baseAdapter,

    bindToComponent({ component }, actions) {
      if (typeof component.onChange === 'function') {
        addComponentEventListener(
          component,
          'onChange',
          actions.refresh,
          applicationCodeZone
        )
      }
    },

    resetUserFilter({ component }) {
      const componentAccessorName = getComponentValueAccessorName(component)
      component[componentAccessorName] = getComponentDefaultValue(component)
    }
  }
}
