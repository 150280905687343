'use strict'

const actionTypes = require('./actionTypes')

module.exports.setFilter = filter => ({
  type: actionTypes.SET_FILTER,
  filter
})

module.exports.setSort = sort => ({
  type: actionTypes.SET_SORT,
  sort
})

module.exports.setIsDatasetReady = isDatasetReady => ({
  type: actionTypes.SET_IS_DATASET_READY,
  isDatasetReady
})

module.exports.setFixedFilterItem = fixedFilterItem => ({
  type: actionTypes.SET_FIXED_FILTER_ITEM,
  fixedFilterItem
})
