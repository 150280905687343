'use strict'

const getFieldReferencedCollection = (fieldName, schema) =>
  schema != null && schema.fields[fieldName] != null
    ? schema.fields[fieldName].referencedCollection
    : null

const getSchemaDisplayField = schema =>
  schema != null ? schema.displayField : null

module.exports = {
  getFieldReferencedCollection,
  getSchemaDisplayField
}
