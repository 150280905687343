'use strict'

const uuid = require('uuid')
const omit_ = require('lodash/omit')

const { isPristine } = require('./symbols')

const getRecordId = record => record._id

const hasDraft = record => typeof record[isPristine] === 'boolean'

const isRecordPristine = record =>
  typeof record[isPristine] !== 'boolean' || record[isPristine]

const markRecordDirty = record => ({
  ...record,
  [isPristine]: false
})

const createDraft = (defaultDraft, recordId) => ({
  ...defaultDraft,
  _id: recordId || uuid.v4(),
  [isPristine]: true
})

const isSameRecord = (a, b) => a && b && a._id === b._id

const cleanseRecord = record => omit_(record, [isPristine])

const mergeRecord = (record, ...fieldsAndValues) => {
  return Object.assign(
    {},
    record,
    ...fieldsAndValues.map(object => omit_(object, ['_id']))
  )
}

module.exports.cleanseRecord = cleanseRecord
module.exports.createDraft = createDraft
module.exports.getRecordId = getRecordId
module.exports.hasDraft = hasDraft
module.exports.isRecordPristine = isRecordPristine
module.exports.isSameRecord = isSameRecord
module.exports.markRecordDirty = markRecordDirty
module.exports.mergeRecord = mergeRecord
