const {
  Checkbox: checkboxSdkType,
  Dropdown: dropdownSdkType
} = require('@wix/dbsm-common/src/componentTypes')

module.exports.getComponentValueAccessorName = component => {
  switch (component.type) {
    case checkboxSdkType:
      return 'checked'
    default:
      return 'value'
  }
}

module.exports.getComponentDefaultValue = component => {
  switch (component.type) {
    case checkboxSdkType:
      return false
    case dropdownSdkType:
      return ''
    default:
      return null
  }
}
