'use strict'

const unset_ = require('lodash/unset')
const get_ = require('lodash/get')
const { TraceType } = require('../logger/traceType')

const controllerStore = logger => {
  const scopesMap = {}
  return {
    setController: ({ compId, itemId }, controller) => {
      logger.trace(
        TraceType.Breadcrumb({
          level: 'info',
          category: 'scopeStore',
          message: 'adding scope',
          data: { componentId: compId, itemId }
        })
      )
      scopesMap[compId] = scopesMap[compId] || {}
      scopesMap[compId][itemId] = controller
    },
    getController: ({ compId, itemId }) => {
      const componentScopes = scopesMap[compId]
      return componentScopes && componentScopes[itemId]
    },
    removeController: ({ compId, itemId }) => {
      logger.trace(
        TraceType.Breadcrumb({
          level: 'info',
          category: 'scopeStore',
          message: 'removing scope',
          data: { componentId: compId, itemId }
        })
      )
      const componentScopes = scopesMap[compId]
      if (get_(componentScopes, itemId)) {
        componentScopes[itemId].dispose()
        unset_(componentScopes, itemId)
      }
    },
    getAll: () => {
      return Object.values(scopesMap).reduce(
        (acc, curr) => acc.concat(Object.values(curr)),
        []
      )
    }
  }
}

module.exports = controllerStore
