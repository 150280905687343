'use strict'

const {
  FILTER_INPUT_ROLE
} = require('@wix/wix-data-client-common/src/connection-config/roles')
const { Maybe } = require('@wix/wix-code-adt')
const { parseStandardFilter } = require('../helpers/parseStandardFilter')
const castValueToFieldType = require('./castValueToFieldType')
const {
  getComponentValueAccessorName
} = require('../helpers/componentValueUtils')

const resolve = (unresolvedFilterValue, components) => {
  const { filterId } = unresolvedFilterValue
  const userFilterComponent = components.find(
    comp => comp.connectionConfig.filters[filterId]
  )

  if (!userFilterComponent) {
    return
  }

  const valueAccessorName = getComponentValueAccessorName(userFilterComponent)
  return userFilterComponent[valueAccessorName]
}

module.exports = ({ getConnectedComponents, getFieldType }) => filter => {
  const connectedComponents = getConnectedComponents()
  if (!connectedComponents) {
    return Maybe.Nothing()
  }

  const filterInputComponents = connectedComponents
    .filter(({ role }) => role === FILTER_INPUT_ROLE)
    .map(({ component }) => component)

  return parseStandardFilter(filter).map(
    ({ field, condition, value, positive }) => {
      const fieldType = getFieldType(field)
      const resolvedValue = resolve(value, filterInputComponents)
      const castValue = castValueToFieldType(fieldType, resolvedValue)

      if (!castValue && castValue !== 0) {
        return { $and: [] }
      }

      const parsedFilter = { [field]: { [condition]: castValue } }

      return positive ? parsedFilter : { $not: [parsedFilter] }
    }
  )
}
