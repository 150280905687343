'use strict'

const mapKeys_ = require('lodash/mapKeys')
const startsWith_ = require('lodash/startsWith')
const assign_ = require('lodash/assign')
const get_ = require('lodash/get')
const { Maybe } = require('@wix/wix-code-adt')

const {
  FieldType: { richText }
} = require('@wix/wix-data-schema-types')

const dynamicPropertiesHandlers = {
  $text: fieldType =>
    fieldType.map(ft => (ft === richText ? 'html' : 'text')).getOrElse('text')
}

const resolveDynamicProperty = (propName, propertyConfig, getFieldType) =>
  Maybe.fromNullable(dynamicPropertiesHandlers[propName])
    .map(propHandler => propHandler(getFieldType(propertyConfig.fieldName)))
    .getOrElse(propName)

const resolveDynamicProperties = ({ connectionConfig }, role, getFieldType) =>
  Maybe.fromNullable(get_(connectionConfig, 'properties'))
    .map(properties =>
      mapKeys_(connectionConfig.properties, (property, propName) =>
        startsWith_(propName, '$')
          ? resolveDynamicProperty(propName, property, getFieldType)
          : propName
      )
    )
    .map(properties => assign_({}, connectionConfig, { properties }))
    .getOrElse(connectionConfig)

exports.resolveDynamicProperties = resolveDynamicProperties
