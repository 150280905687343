'use strict'

const { union } = require('@wix/wix-code-adt')
const { matchAny } = require('@wix/wix-code-client-logger')
const noop_ = require('lodash/noop')
const merge_ = require('lodash/merge')
const {
  DBSMViewer,
  UserErrors,
  WixData
} = require('@wix/dbsm-common/src/raven/apps')
const setupRaven = require('../raven/setupRaven')
const {
  errorBoundaryScopes: { USER_SCOPE, WIX_DATA_SCOPE, APPLICATION_SCOPE }
} = require('../error-boundaries/errorBoundaries')

const ravenHandlerCreator = ({ global: globalScope, appName }) => () => {
  const createReportOptions = ({ level, sessionData, options = {} }) => {
    return merge_({ level }, { extra: sessionData }, options)
  }
  let zoneToRavenMap = {}

  const getZoneReporter = zone => {
    const raven = zoneToRavenMap[zone]
    if (!raven) {
      throw new Error('Raven was not initialized')
    }
    return raven
  }

  return {
    init: ({ user, createRavenClient, platformBiParams }) => {
      const Raven = createRavenClient(DBSMViewer.dsn)
      const UserRaven = createRavenClient(UserErrors.dsn)
      const WixDataRaven = createRavenClient(WixData.dsn)
      const params = { tags: { msid: platformBiParams.metaSiteId } }

      setupRaven({
        Raven,
        globalScope,
        dsn: DBSMViewer.dsn,
        appName,
        user,
        params
      })
      setupRaven({
        Raven: UserRaven,
        globalScope,
        dsn: UserErrors.dsn,
        appName,
        user,
        params
      })
      setupRaven({
        Raven: WixDataRaven,
        globalScope,
        dsn: WixData.dsn,
        appName,
        user,
        params
      })

      zoneToRavenMap = {
        [APPLICATION_SCOPE]: Raven,
        [USER_SCOPE]: UserRaven,
        [WIX_DATA_SCOPE]: WixDataRaven
      }
    },
    log: logEvent => {
      logEvent.matchWith({
        Trace: ({ payload }) =>
          payload.matchWith({
            Breadcrumb: breadcrumb =>
              getZoneReporter(APPLICATION_SCOPE).captureBreadcrumb(breadcrumb),
            [union.any]: noop_
          }),
        Info: ({ message, options, sessionData }) => {
          getZoneReporter(APPLICATION_SCOPE).captureMessage(
            message,
            createReportOptions({ level: 'info', sessionData, options })
          )
        },
        Warn: ({ message, options, sessionData }) => {
          getZoneReporter(APPLICATION_SCOPE).captureMessage(
            message,
            createReportOptions({ level: 'warning', sessionData, options })
          )
        },
        Error: ({ error, options, sessionData }) =>
          getZoneReporter(options.zone).captureException(
            error,
            createReportOptions({ sessionData, options })
          ),
        [matchAny]: noop_
      })
    }
  }
}

module.exports.ravenHandlerCreator = ravenHandlerCreator
