'use strict'

const cloneDeep_ = require('lodash/cloneDeep')
const noop_ = require('lodash/noop')

const createControllerStore = require('../dataset-controller/controllerStore')
const initCreateDataset = require('../dataset-controller/controller')

const addScopeToAllHandshakes = (handshakes, { compId, itemId }) => {
  if (handshakes) {
    return handshakes.map(handshake => ({
      ...handshake,
      controllerApi: handshake.controllerApi.inScope(compId, itemId)
    }))
  }
}

const createScopedDatasetId = (datasetId, { compId, itemId }) =>
  [datasetId, 'componentId', compId, 'itemId', itemId].join('_')

const controllerFactory = (logger, datasetParams) => {
  const controllerStore = createControllerStore(logger)
  const factory = {
    createPrimaryController: () => primaryController,

    createDetailsController: ({ scopeInfo, scoped$w }) =>
      createDataset(true, false, {
        ...datasetParams,
        firePlatformEvent: noop_,
        datasetId: createScopedDatasetId(datasetParams.datasetId, scopeInfo),
        handshakes: addScopeToAllHandshakes(
          datasetParams.handshakes,
          scopeInfo
        ),
        $w: scoped$w
      }),

    createFixedItemController: ({
      scopeInfo,
      fixedItem,
      parentId,
      scoped$w
    }) => {
      const { dynamicPagesData } = datasetParams.routerData || {}
      const newControllerConfig = cloneDeep_(datasetParams.controllerConfig)
      newControllerConfig.dataset.filter = datasetParams.wixDataProxy
        .filter()
        .eq('_id', fixedItem._id)
        ._build()

      return createDataset(true, true, {
        ...datasetParams,
        controllerConfig: newControllerConfig,
        firePlatformEvent: noop_,
        routerData: {
          dynamicPagesData,
          prefetchedData: { items: [fixedItem], totalCount: 1 }
        },
        datasetId: createScopedDatasetId(datasetParams.datasetId, scopeInfo),
        parentId,
        $w: scoped$w
      })
    }
  }
  const createDataset = initCreateDataset(factory, controllerStore)
  const primaryController = createDataset(false, false, datasetParams)

  return factory
}

module.exports = controllerFactory
