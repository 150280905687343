'use strict'

const ExtendableError = require('es6-error')
const { setErrorScope, errorBoundaryScopes } = require('./errorBoundaries')

class UserError extends ExtendableError {
  constructor(message) {
    super(message)
    setErrorScope(this, errorBoundaryScopes.USER_SCOPE)
  }
}
module.exports = UserError
