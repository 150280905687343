'use strict'

const { Maybe } = require('@wix/wix-code-adt')

const currentUserFilterResolver = wixCodeSdk => () =>
  Maybe.fromNullable(wixCodeSdk.user.currentUser).map(({ id, loggedIn }) =>
    loggedIn ? id : null
  )

module.exports = currentUserFilterResolver
