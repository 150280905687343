const flow_ = require('lodash/flow')
const formatValue = require('./formatValue')
const convertValue = require('./transformFromRecordToView')
const transformFromViewToRecord = require('./transformFromViewToRecord')

const transformFromRecordToView = ({
  value,
  role,
  fieldType,
  propPath,
  componentIsInput,
  format,
  utils: { formatter, mediaItemUtils } = {}
}) =>
  flow_([
    value => formatValue(value, formatter, { format }),
    value =>
      convertValue(value, {
        fieldType,
        role,
        componentIsInput,
        propPath,
        mediaItemUtils
      })
  ])(value)

module.exports = {
  transformFromRecordToView,
  transformFromViewToRecord
}
