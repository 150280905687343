'use strict'

const some_ = require('lodash/some')
const values_ = require('lodash/values')
const omit_ = require('lodash/omit')
const get_ = require('lodash/get')
const isEmpty_ = require('lodash/isEmpty')
const {
  UploadButton: uploadButtonSdkType
} = require('@wix/dbsm-common/src/componentTypes')

const isUploadButtonValid = (component, record, connectionConfig) => {
  if (component.validity.valid) {
    return true
  }

  const hasErrorOtherThanValueMissing = some_(
    values_(omit_(component.validity, ['valid', 'valueMissing'])),
    value => value
  )
  if (hasErrorOtherThanValueMissing) {
    return false
  }

  const fieldConnectedToValue = get_(
    connectionConfig,
    'properties.value.fieldName'
  )

  return !isEmpty_(record[fieldConnectedToValue])
}

module.exports = ({ component, connectionConfig }, currentRecord) => {
  if (component.type === uploadButtonSdkType) {
    return isUploadButtonValid(component, currentRecord, connectionConfig)
  }

  // Feature detection for validationMixin
  if (typeof component.validity === 'object') {
    return component.validity.valid
  }

  return true
}
