'use strict'

const noop_ = require('lodash/noop')
const { union } = require('@wix/wix-code-adt')
const { matchAny } = require('@wix/wix-code-client-logger')
const sendBiOnError = require('@wix/dbsm-common/src/bi/sendBiOnError')
const { setupBiLogger, setupErrorBiLogger } = require('../bi/setupBiLogger')
const getDefaultBIParams = require('../bi/getDefaults')
const {
  errorBoundaryScopes: { USER_SCOPE }
} = require('../error-boundaries/errorBoundaries')

const Environment = union('Environment', {
  NotInitialized: () => {},
  SSR: () => {},
  Client: ({ viewMode, platformBiParams, biLoggerFactory }) => {
    const biLogger = setupBiLogger(
      viewMode,
      getDefaultBIParams(platformBiParams, viewMode),
      biLoggerFactory
    )
    const errorBiLogger = setupErrorBiLogger(biLoggerFactory)
    return { biLogger, errorBiLogger }
  }
})

const biHandlerCreator = () => {
  let environment = Environment.NotInitialized()

  const biHandler = () => ({
    init: ({ inSsr, viewMode, platformBiParams, biLoggerFactory }) => {
      environment = inSsr
        ? Environment.SSR()
        : Environment.Client({ viewMode, platformBiParams, biLoggerFactory })
    },
    log: logEvent => {
      logEvent.matchWith({
        BI: ({ biEvent }) => {
          environment.matchWith({
            Client: ({ biLogger }) => biLogger.log(biEvent),
            SSR: noop_,
            NotInitialized: () => {
              throw new Error(
                `You cannot report to BI before setting the logger environment.
                  Make sure you call logger.init before reporting.`
              )
            }
          })
        },
        Error: ({ error, options: { zone } }) => {
          environment.matchWith({
            Client: ({ errorBiLogger }) => {
              if (zone !== USER_SCOPE) {
                sendBiOnError(event => errorBiLogger.log(event), error)
              }
            },
            [union.any]: noop_
          })
        },
        [matchAny]: () => {}
      })
    }
  })

  return biHandler
}

module.exports.biHandlerCreator = biHandlerCreator
