'use strict'

const { union } = require('@wix/wix-code-adt')

const {
  getCurrentPageSize,
  getPaginationData,
  getNumberOfPagesToShow
} = require('../dataset-controller/rootReducer')
const actionTypes = require('./actionTypes')
const QueryResults = require('../helpers/queryResults')

const GoToIndexResult = union('GoToIndexResult', {
  Record: (index, record) => ({ index, record }),
  InvalidIndex: () => ({}),
  NoRecord: () => ({})
})

const setCurrentIndex = (index, suppressRefreshView = false) => ({
  type: actionTypes.GO_TO_INDEX,
  index,
  suppressRefreshView
})

const goToRecordByIndexResult = (error, payload) => ({
  type: actionTypes.GET_RECORD_BY_INDEX_RESULT,
  error,
  payload
})

const refreshCurrentRecord = () => ({
  type: actionTypes.REFRESH_CURRENT_RECORD
})

const refreshCurrentView = () => ({
  type: actionTypes.REFRESH_CURRENT_VIEW
})

const setCurrentRecord = (record, recordIndex, updateSource) => ({
  type: actionTypes.SET_CURRENT_RECORD,
  record,
  recordIndex,
  updateSource
})

const updateFields = (fieldsToUpdate, updateSource) => ({
  type: actionTypes.UPDATE_FIELDS,
  fieldsToUpdate,
  updateSource
})

const incrementNumOfPagesToShow = () => ({
  type: actionTypes.INCREMENT_NUM_PAGES_TO_SHOW
})

const nextPage = () => ({
  type: actionTypes.GO_TO_NEXT_PAGE
})

const previousPage = () => ({
  type: actionTypes.GO_TO_PREVIOUS_PAGE
})

const loadPage = pageNumber => ({
  type: actionTypes.LOAD_PAGE,
  pageNumber
})

const updateCurrentViewResult = (error, payload) => ({
  type: actionTypes.CURRENT_VIEW_UPDATED,
  error,
  payload
})

const revert = () => ({
  type: actionTypes.REVERT_CHANGES
})

const revertResult = (error, payload) => ({
  type: actionTypes.RECORD_REVERTED,
  error,
  payload
})

const flushDraft = () => ({
  type: actionTypes.SAVE_RECORD
})

const saveRecordResult = (error, payload) => ({
  type: actionTypes.SAVE_RECORD_RESULT,
  error,
  payload
})

const setDefaultRecord = record => ({
  type: actionTypes.SET_DEFAULT_RECORD,
  record
})

const newRecord = atIndex => ({
  type: actionTypes.NEW_RECORD,
  atIndex
})

const newRecordResult = (error, payload) => ({
  type: actionTypes.NEW_RECORD_RESULT,
  error,
  payload
})

const remove = () => ({
  type: actionTypes.REMOVE_CURRENT_RECORD
})

const removeCurrentRecordResult = (error, payload) => ({
  type: actionTypes.REMOVE_CURRENT_RECORD_RESULT,
  error,
  payload
})

const refresh = () => ({
  type: actionTypes.REFRESH
})

const refreshResult = (error, payload) => ({
  type: actionTypes.GET_RECORD_BY_INDEX_RESULT,
  error,
  payload
})

const reInitWriteOnly = () => newRecord(0)

const initWriteOnly = isScoped => (isScoped ? setCurrentIndex(0) : newRecord(0))

const doFetch = async (recordStore, fromIndex, length, byRefField) => {
  const fetchedItems = await recordStore(byRefField).fold(
    () => QueryResults.Empty(),
    service => service.getRecords(fromIndex, length)
  )

  return fetchedItems.get()
}

const fetchCurrentPage = (recordStore, state) => {
  const pageSize = getCurrentPageSize(state) * getNumberOfPagesToShow(state)
  const { offset } = getPaginationData(state)

  return doFetch(recordStore, offset, pageSize)
}

exports.doFetch = doFetch
exports.fetchCurrentPage = fetchCurrentPage
exports.flushDraft = flushDraft
exports.goToRecordByIndexResult = goToRecordByIndexResult
exports.incrementNumOfPagesToShow = incrementNumOfPagesToShow
exports.initWriteOnly = initWriteOnly
exports.loadPage = loadPage
exports.newRecord = newRecord
exports.newRecordResult = newRecordResult
exports.nextPage = nextPage
exports.previousPage = previousPage
exports.reInitWriteOnly = reInitWriteOnly
exports.refresh = refresh
exports.refreshCurrentRecord = refreshCurrentRecord
exports.refreshCurrentView = refreshCurrentView
exports.refreshResult = refreshResult
exports.remove = remove
exports.removeCurrentRecordResult = removeCurrentRecordResult
exports.revert = revert
exports.revertResult = revertResult
exports.saveRecordResult = saveRecordResult
exports.setCurrentIndex = setCurrentIndex
exports.setCurrentRecord = setCurrentRecord
exports.setDefaultRecord = setDefaultRecord
exports.updateCurrentViewResult = updateCurrentViewResult
exports.updateFields = updateFields
exports.GoToIndexResult = GoToIndexResult
