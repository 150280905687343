'use strict'

const { isLocalhost } = require('@wix/dbsm-common/src/worker/getUrl')
const { loggerCreator } = require('./viewerLogger')
const { consoleHandlerCreator } = require('@wix/wix-code-client-logger')
const { id: systemTracingId } = require('./handlers/systemTracing')
const { id: fedopsId } = require('./handlers/fedops')

const traceHandlerIds = {
  SYSTEM_TRACING: systemTracingId,
  FEDOPS: fedopsId
}

const loggerWithHandlers = ({ global, appName }) => {
  const { consoleHandler } = consoleHandlerCreator({
    shouldLog: isLocalhost
  })

  return loggerCreator({
    global,
    appName,
    consoleHandler
  })
}

module.exports.logger = loggerWithHandlers
module.exports.traceHandlerIds = traceHandlerIds
