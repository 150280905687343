'use strict'
const {
  selectCurrentRecord,
  selectCurrentRecordIndex
} = require('../../dataset-controller/rootReducer')
const get_ = require('lodash/get')
const values_ = require('lodash/values')
const isEmpty_ = require('lodash/isEmpty')
const forEach_ = require('lodash/forEach')
const reduce_ = require('lodash/reduce')
const { getFieldValue } = require('./helpers')
const { transformFromRecordToView } = require('../transformData')
const baseAdapter = require('./baseAdapter')

module.exports = ({
  getState,
  wixSdk,
  errorReporter,
  getFieldType,
  databindingVerboseReporter,
  wixFormatter
}) => {
  const logVerboseForBinding = (component, connectionConfig) => {
    const { properties } = connectionConfig
    const bindingDescription = {}

    forEach_(properties, ({ fieldName }, propName) => {
      bindingDescription[propName] = fieldName
    })
    databindingVerboseReporter.logBinding({
      component,
      bindingDescription
    })
  }

  const currentRecordModified = ({
    component,
    role,
    connectionConfig: { properties }
  }) => {
    const record = selectCurrentRecord(getState())
    const currentIndex = selectCurrentRecordIndex(getState())
    component.markers[currentIndex] = createMarkerFromRecord(
      record,
      properties,
      role
    )
    component.setCenter(get_(component, ['markers', currentIndex, 'location']))
  }

  const setCurrentCenterMarkerOfMap = ({ component }) => {
    const currentIndex = selectCurrentRecordIndex(getState())
    component.setCenter(get_(component, ['markers', currentIndex, 'location']))
  }

  const createMarkerFromRecord = (record, properties = [], role) =>
    reduce_(
      properties,
      (marker, { fieldName, format }, propPath) => {
        const fieldValue = getFieldValue(record, fieldName)
        //TODO: move this transformation to transformFromRecordToView
        if (propPath === 'address') {
          return Object.assign(marker, {
            address: get_(fieldValue, 'formatted'),
            location: get_(fieldValue, 'location')
          })
        } else if (propPath === 'link' && isEmpty_(fieldValue)) {
          return marker
        }

        const convertedValue = transformFromRecordToView({
          value: getFieldValue(record, fieldName),
          role,
          fieldType: getFieldType(fieldName).getOrElse(''),
          propPath,
          format,
          utils: {
            formatter: wixFormatter
          }
        })
        return Object.assign(marker, {
          [propPath]: convertedValue
        })
      },
      {}
    )

  const updateComponentFromRecords = async (
    { connectionConfig: { properties }, component, role },
    actions
  ) => {
    try {
      const { items: records } = await actions.fetchCurrentItems(getState())
      component.markers = records.map(record =>
        createMarkerFromRecord(record, properties, role)
      )
    } catch (e) {
      errorReporter(`Failed setting markers:`, e)
    }
  }

  return {
    ...baseAdapter,

    clearComponent({ component }) {
      component.markers = []
    },

    isValidContext({ connectionConfig }) {
      return values_(connectionConfig).find(
        configValue => !isEmpty_(configValue)
      )
    },

    bindToComponent({ connectionConfig, component }) {
      logVerboseForBinding(component, connectionConfig)
    },

    currentRecordModified,
    recordSetLoaded: updateComponentFromRecords,
    currentViewChanged: updateComponentFromRecords,

    currentIndexChanged(componentAdapterContext) {
      setCurrentCenterMarkerOfMap(componentAdapterContext)
    }
  }
}
