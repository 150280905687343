'use strict'
/* global self */
/* eslint-disable no-console */
/* global VERBOSE */

try {
  const appName = 'dbsm-viewer-app'

  const {
    viewerAutomationsClientCreator
  } = require('@wix/wix-code-automations-client')
  const {
    createDataSchemasClientForBrowser
  } = require('@wix/wix-data-schemas-client')
  const createApp = require('./appCreator')
  const parseUrl = require('url-parse')

  const createWixDataSchemasClient = (
    elementorySupport,
    siteBaseUrl,
    envIsEditor
  ) => {
    const {
      query: { instance, gridAppId }
    } = parseUrl(`?${elementorySupport.queryParameters}`, true)

    const { protocol, hostname } = parseUrl(siteBaseUrl)

    const baseUrl = envIsEditor
      ? undefined
      : `${protocol}//${hostname}/_api/cloud-data/v1/schemas`

    return createDataSchemasClientForBrowser(instance, gridAppId, {
      baseUrl
    })
  }

  const { logger: loggerCreator } = require('../logger')
  const appLogger = loggerCreator({
    global: self,
    appName
  })

  const errorReporter = (message, error) => console.error(message, error) // eslint-disable-line no-console
  const verboseReporter = (...args) => console.verbose(...args)
  const shouldVerbose = !!VERBOSE
  const automationsClient = viewerAutomationsClientCreator({
    requestFunction: global.fetch,
    elementorySupport: global.elementorySupport
  })

  const app = createApp({
    wixDataCreator: ({ baseUrl, envIsEditor }) => ({
      wixData: self.require('wix-data').default,
      wixDataSchemas: createWixDataSchemasClient(
        global.elementorySupport,
        baseUrl,
        envIsEditor
      )
    }),
    elementorySupport: global.elementorySupport,
    errorReporter,
    verboseReporter,
    shouldVerbose,
    appLogger,
    automationsClient
  })

  module.exports = app
} catch (e) {
  console.error(e.stack)
  throw e
}
