const {
  wixCodeItemToProGallery,
  proGalleryItemToWixCode
} = require('@wix/pro-gallery-items-formatter')
const {
  FieldType: { mediaGallery }
} = require('@wix/wix-data-schema-types')

const convertUploadedFileUrlToMediaGalleryItem = ({
  uploadedFileUrl,
  mediaItemUtils
}) => {
  const mediaItem = mediaItemUtils.parseMediaItemUri(uploadedFileUrl)
  const mediaItemUri = mediaItemUtils.createMediaItemUri(mediaItem).item
  const proGalleryItem = wixCodeItemToProGallery({
    ...mediaItem,
    src: mediaItemUri
  })

  return proGalleryItemToWixCode(proGalleryItem)
}

module.exports = ({ value, currentValue, fieldType, mediaItemUtils }) => {
  const files = Array.isArray(value) ? value : [value]

  return fieldType === mediaGallery
    ? [
        ...currentValue,
        ...files.map(({ url: uploadedFileUrl }) =>
          convertUploadedFileUrlToMediaGalleryItem({
            uploadedFileUrl,
            mediaItemUtils
          })
        )
      ]
    : files[0].url
}
