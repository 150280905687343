'use strict'

const actionTypes = {
  INIT: 'INIT',
  SET_PAGINATION_DATA: 'SET_PAGINATION_DATA'
}

const setPaginationData = paginationData => ({
  type: actionTypes.SET_PAGINATION_DATA,
  paginationData
})

const init = ({
  controllerConfig = {},
  connections = [],
  isScoped = false,
  datasetType
}) => ({
  type: actionTypes.INIT,
  datasetConfig: controllerConfig.dataset || {},
  connections,
  isScoped,
  datasetType
})

module.exports.actionTypes = actionTypes

module.exports.init = init
module.exports.setPaginationData = setPaginationData
