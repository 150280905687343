'use strict'

const { Maybe } = require('@wix/wix-code-adt')
const isPlainObject_ = require('lodash/isPlainObject')
const flatMap_ = require('lodash/flatMap')
const set_ = require('lodash/set')
const cloneDeep_ = require('lodash/cloneDeep')
const clone_ = require('lodash/clone')

const sequence = require('@wix/dbsm-common/src/fp/sequence')

const isFilterLeaf = filterExpression =>
  !Array.isArray(filterExpression) && !isPlainObject_(filterExpression)

const getFilterPartsByPredicate = (
  predicate,
  filterOrExpression,
  path = []
) => {
  if (predicate(filterOrExpression)) {
    return [
      {
        path: clone_(path),
        filterExpression: filterOrExpression
      }
    ]
  }

  if (!isFilterLeaf(filterOrExpression)) {
    return flatMap_(filterOrExpression, (value, key) =>
      getFilterPartsByPredicate(predicate, value, path.concat(key))
    )
  }

  return []
}

const mutateMergeValueAtPath = (filter, path, resolvedValue) =>
  set_(filter, path, resolvedValue)

// transformFilterExpressions :: (FilterExpression -> Maybe FilterExpression) -> [filterPart] -> Maybe [filterPart]
const transformFilterParts = (transformer, filterParts) =>
  sequence(
    Maybe,
    filterParts.map(({ path, filterExpression }) => {
      const maybeTransformedExpression = transformer(filterExpression)
      return maybeTransformedExpression.map(filterExpression => ({
        path,
        filterExpression
      }))
    })
  )

// mergeFilterParts :: Filter -> [FilterParts] -> Filter
const mergeFilterParts = filter => filterParts =>
  filterParts.reduce(
    (mergedFilter, { path, filterExpression }) =>
      mutateMergeValueAtPath(mergedFilter, path, filterExpression),
    cloneDeep_(filter)
  )

// tranformFilter :: (predicateFunction, transformFunction) -> Filter -> Maybe Filter
const transformFilter = (predicate, transformer, filter) => {
  const filterPartsToTransform = getFilterPartsByPredicate(predicate, filter)

  const maybeTransformedFilterParts = transformFilterParts(
    transformer,
    filterPartsToTransform
  )

  const maybeTransformedFilter = maybeTransformedFilterParts.map(
    mergeFilterParts(filter)
  )

  return maybeTransformedFilter
}

module.exports.getFilterPartsByPredicate = getFilterPartsByPredicate
module.exports.transformFilter = transformFilter
