const get_ = require('lodash/get')
const {
  FieldType: { number, stringArray }
} = require('@wix/wix-data-schema-types')

module.exports = (fieldType, value) =>
  fieldType
    .map(type => {
      if (
        type === number &&
        typeof value === 'string' &&
        /^[+-]?((\d+\.?\d*)|(\d*\.?\d+))$/.test(value.trim())
      ) {
        return Number(value)
      }

      if (type === stringArray && get_(value, 'length', 0) === 0) {
        return null
      }

      return value
    })
    .getOrElse(value)
