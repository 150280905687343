'use strict'
const includes_ = require('lodash/includes')
const {
  isFieldFromReferencedCollection,
  getReferenceFieldName
} = require('@wix/dbsm-common/src/reference-fields/fieldPath')

const shouldSetAllFields = updatedFields => updatedFields.length === 0

const wasFieldUpdated = (fieldName, updatedFields) =>
  includes_(
    updatedFields,
    isFieldFromReferencedCollection(fieldName)
      ? getReferenceFieldName(fieldName)
      : fieldName
  )

module.exports = ({ updatedFields, fieldName }) =>
  shouldSetAllFields(updatedFields) || wasFieldUpdated(fieldName, updatedFields)
