'use strict'

const flow_ = require('lodash/flow')
const curry_ = require('lodash/curry')
const mapValues_ = require('lodash/mapValues')

const { assign, bind } = require('./utils')
const { freshCollection, fromWarmupCollection } = require('./collections')

const freshStore = mainCollectionName => ({
  [mainCollectionName]: {
    ...freshCollection()
  }
})

const fromWarmupStore = warmupStore => {
  return mapValues_(warmupStore, fromWarmupCollection)
}

const setCollection = curry_((name, collection) =>
  assign({ [name]: collection })
)

const getCollection = collectionName => store => store[collectionName]

const updateCollection = (collectionName, updateFn) =>
  bind(
    flow_(getCollection(collectionName), updateFn),
    setCollection(collectionName)
  )

module.exports.freshStore = freshStore
module.exports.fromWarmupStore = fromWarmupStore
module.exports.getCollection = getCollection
module.exports.setCollection = setCollection
module.exports.updateCollection = curry_(updateCollection)
