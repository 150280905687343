const readWriteModes = require('@wix/wix-data-client-common/src/dataset-configuration/readWriteModes')

const getCollectionName = (datasetConfig = {}) => datasetConfig.collectionName

const getPageSize = (datasetConfig = {}) => datasetConfig.pageSize

const isWriteOnly = (datasetConfig = {}) =>
  datasetConfig.readWriteType === readWriteModes.WRITE

const getReadWriteType = (datasetConfig = {}) => datasetConfig.readWriteType

module.exports.getCollectionName = getCollectionName
module.exports.getPageSize = getPageSize
module.exports.isWriteOnly = isWriteOnly
module.exports.getReadWriteType = getReadWriteType
