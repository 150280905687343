'use strict'

const {
  isFieldFromReferencedCollection,
  getReferenceFieldName,
  getFieldFromReferencedCollectionName
} = require('@wix/dbsm-common/src/reference-fields/fieldPath')
const get_ = require('lodash/get')

const getFieldTypeCreator = (schema, relatedSchemas) => fieldName => {
  if (isFieldFromReferencedCollection(fieldName)) {
    const referenceFieldName = getReferenceFieldName(fieldName)
    const fieldFromReferencedCollectionName = getFieldFromReferencedCollectionName(
      fieldName
    )
    const referencedCollectionName =
      schema && schema.fields[referenceFieldName]
        ? schema.fields[referenceFieldName].referencedCollection
        : null
    const referencedSchema =
      relatedSchemas && referencedCollectionName
        ? relatedSchemas[referencedCollectionName]
        : null

    return get_(referencedSchema, [
      'fields',
      fieldFromReferencedCollectionName,
      'type'
    ])
  }
  return get_(schema, ['fields', fieldName, 'type'])
}

module.exports = getFieldTypeCreator
