const formatTypes = require('@wix/dbsm-common/src/connection-config/formatTypes')
const isDate_ = require('lodash/isDate')

const formatByType = (value, formatter, { format, wixSdk }) => {
  switch (format.type) {
    case formatTypes.DATETIME:
      if (!isDate_(value)) {
        return value
      }

      if (!formatter) {
        return ''
      }

      return formatter.formatDateTime(value, format.params.dateFormat)
    default:
      return value
  }
}

module.exports = (value, formatter, { format, wixSdk }) => {
  if (format) {
    return formatByType(value, formatter, { format, wixSdk })
  }

  return value
}
