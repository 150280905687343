const entries_ = require('lodash/entries')
const isPlainObject_ = require('lodash/isPlainObject')
const isArray_ = require('lodash/isArray')
const { Maybe } = require('@wix/wix-code-adt')

// parseStandardFilter :: FilterExpression -> Maybe {field, condition, value, positive}
module.exports.parseStandardFilter = filterExpression =>
  getFirstEntry(filterExpression)
    .chain(([operator, operands]) =>
      operator === '$not' && isArray_(operands)
        ? parseStandardFilterSigned({
            positive: false,
            filterExpression: operands[0]
          })
        : Maybe.Nothing()
    )
    .orElse(() =>
      parseStandardFilterSigned({ positive: true, filterExpression })
    )

// parseStandardFilterSigned :: {positive, filterExpression} -> Maybe {field, condition, value, positive}
const parseStandardFilterSigned = ({ positive, filterExpression }) =>
  getFirstEntry(filterExpression).chain(
    ([field, filterExpressionAtCondition]) =>
      getFirstEntry(filterExpressionAtCondition).map(([condition, value]) => ({
        field,
        condition,
        value,
        positive
      }))
  )

// getFirstEntry :: Object => Maybe [key, value]
const getFirstEntry = object => {
  if (!isPlainObject_(object)) {
    return Maybe.Nothing()
  }

  const firstEntry = entries_(object)[0]

  return Maybe.fromNullable(firstEntry)
}
