const actionTypes = require('./actionTypes')

const initialize = connections => ({
  type: actionTypes.INITIALIZE,
  connections
})

const setNextDynamicPageUrl = url => ({
  type: actionTypes.NEXT_DYNAMIC_PAGE_URL_RESULT,
  url
})

const setPreviousDynamicPageUrl = url => ({
  type: actionTypes.PREVIOUS_DYNAMIC_PAGE_URL_RESULT,
  url
})

module.exports.initialize = initialize
module.exports.setNextDynamicPageUrl = setNextDynamicPageUrl
module.exports.setPreviousDynamicPageUrl = setPreviousDynamicPageUrl
