'use strict'

const omit_ = require('lodash/omit')
const createBreadcrumbMessage = require('../helpers/createBreadcrumbMessage')
const { TraceType } = require('../logger/traceType')

const createTraceReduxMiddleware = (
  logger,
  datasetId
) => store => next => action => {
  logger.trace(
    TraceType.Breadcrumb({
      category: 'redux',
      message: createBreadcrumbMessage(action.type, datasetId),
      data: omit_(action, 'type', 'record')
    })
  )

  return next(action)
}

module.exports.createTraceReduxMiddleware = createTraceReduxMiddleware
