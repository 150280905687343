const isModePreview = viewMode => viewMode === 'Preview'
const isModeLivePreview = viewMode => viewMode === 'Editor'
const isEnvLive = viewMode => viewMode === 'Site'
const isEnvEditor = viewMode =>
  isModePreview(viewMode) || isModeLivePreview(viewMode)

module.exports = {
  isModePreview,
  isModeLivePreview,
  isEnvLive,
  isEnvEditor
}
