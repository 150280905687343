'use strict'

const {
  registerToUnexpectedErrors
} = require('@wix/dbsm-common/src/errors/registerToWorkerUnexpectedErrors')
const errorBoundariesCreator = require('./error-boundaries/errorBoundaries')
const { isErrorHandled } = require('./error-boundaries/errorBoundaries')
const { ravenHandlerCreator } = require('./handlers/raven')
const { biHandlerCreator } = require('./handlers/bi')
const { systemTracingHandlerCreator } = require('./handlers/systemTracing')
const { fedopsHandlerCreator } = require('./handlers/fedops')
const {
  errorBoundaryScopes: { USER_SCOPE, WIX_DATA_SCOPE, APPLICATION_SCOPE }
} = require('./error-boundaries/errorBoundaries')
const { create: createLogger } = require('@wix/wix-code-client-logger')

const viewerLogger = ({ global, appName, consoleHandler }) => {
  const ravenHandler = ravenHandlerCreator({
    global,
    appName
  })
  const biHandler = biHandlerCreator()
  const systemTracingHandler = systemTracingHandlerCreator()
  const fedopsHandler = fedopsHandlerCreator()
  const logger = createLogger({
    handlerCreators: [
      consoleHandler,
      ravenHandler,
      biHandler,
      systemTracingHandler,
      fedopsHandler
    ]
  })

  const reportErrorWithZone = zone => (error, options) => {
    const optionsWithZone = {
      ...options,
      zone
    }
    logger.error(error, optionsWithZone)
  }

  const errorBoundaries = errorBoundariesCreator({
    [USER_SCOPE]: reportErrorWithZone(USER_SCOPE),
    [WIX_DATA_SCOPE]: reportErrorWithZone(WIX_DATA_SCOPE),
    [APPLICATION_SCOPE]: reportErrorWithZone(APPLICATION_SCOPE)
  })

  registerToUnexpectedErrors({
    onError: error => {
      if (!isErrorHandled(error)) {
        const options = {
          level: 'info',
          tags: { unHandledRejection: true }
        }
        reportErrorWithZone(APPLICATION_SCOPE)(error, options)
      }
    },
    appName,
    global
  })

  const loggerWithZones = {
    ...logger,
    error: reportErrorWithZone(APPLICATION_SCOPE),
    applicationCodeZone: errorBoundaries.applicationCodeZone,
    userCodeZone: errorBoundaries.userCodeZone,
    wixDataCodeZone: errorBoundaries.wixDataCodeZone
  }

  return loggerWithZones
}

module.exports.loggerCreator = viewerLogger
