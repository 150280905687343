const invoke_ = require('lodash/invoke')
const performanceIsDefined = typeof performance !== 'undefined' // in node we can't take this var from global scope

module.exports = {
  mark: performanceIsDefined
    ? (...args) => invoke_(performance, 'mark', ...args)
    : () => {},
  measure: performanceIsDefined
    ? (...args) => invoke_(performance, 'measure', ...args)
    : () => {}
}
