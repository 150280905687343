'use strict'

const identity_ = require('lodash/identity')
const mapValues_ = require('lodash/mapValues')
const omit_ = require('lodash/omit')
const has_ = require('lodash/has')
const {
  configureForViewerWorker
} = require('@wix/dbsm-common/src/raven/configureForViewerWorker')

const addDataFixer = raven => {
  const removeRecordsIfExists = value =>
    has_(value, 'state.records') ? omit_(value, 'state.records') : value

  const removeUserData = data => mapValues_(data, removeRecordsIfExists)

  raven.setDataCallback((data, originalCallback = identity_) => {
    const finalData = originalCallback(data)
    finalData.extra = removeUserData(finalData.extra)

    return finalData
  })
}

module.exports = ({ Raven, globalScope, dsn, params, appName, user }) => {
  configureForViewerWorker({ Raven, globalScope, dsn, params, appName })

  addDataFixer(Raven)
  Raven.setUserContext(user)
}
