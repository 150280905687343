'use strict'

const adapterApiCreator = require('./adapterApi')
const {
  createComponentAdapterContexts,
  createDetailsRepeatersAdapterContexts
} = require('./createComponentAdapter')

const initAdapters = require('./initAdapters')

module.exports = {
  adapterApiCreator,
  createComponentAdapterContexts,
  createDetailsRepeatersAdapterContexts,
  initAdapters
}
