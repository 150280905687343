const flow_ = require('lodash/flow')

const {
  FieldType: { reference, dateTime }
} = require('@wix/wix-data-schema-types')
const {
  UPLOAD_BUTTON_ROLE
} = require('@wix/wix-data-client-common/src/connection-config/roles')

const convertDateTime = require('./convertDateTime')
const convertUploadResult = require('./convertUploadResult')

module.exports = ({
  value,
  currentValue,
  fieldType,
  fieldName,
  role,
  utils: { referenceFetcher, mediaItemUtils } = {}
}) => {
  const arrayOfConvertorsAndConditions = [
    {
      converter: value =>
        referenceFetcher(
          value, // referenced record id
          fieldName
        ),
      condition: fieldType === reference
    },
    {
      converter: value => convertDateTime(currentValue, value),
      condition: fieldType === dateTime
    },
    {
      converter: value =>
        convertUploadResult({
          value,
          currentValue,
          fieldType,
          mediaItemUtils
        }),
      condition: role === UPLOAD_BUTTON_ROLE
    }
  ]

  const conversionFlow = arrayOfConvertorsAndConditions.reduce(
    (acc, { converter, condition }) => {
      if (condition) acc.push(converter)
      return acc
    },
    []
  )
  return flow_(conversionFlow)(value)
}
