'use strict'

const { union } = require('@wix/wix-code-adt')
const { matchAny } = require('@wix/wix-code-client-logger')

const filterByReportToHandlers = (id, logFn) => logEvent => {
  logEvent.matchWith({
    Trace: ({ payload }) => {
      payload.matchWith({
        Action: ({ options }) => {
          if (options.reportToHandlers.includes(id)) {
            logFn(logEvent)
          }
        },
        [union.any]: () => logFn(logEvent)
      })
    },
    [matchAny]: () => logFn(logEvent)
  })
}

module.exports.filterByReportToHandlers = filterByReportToHandlers
