const { createStore: createReduxStore, applyMiddleware } = require('redux')
const { createTraceReduxMiddleware } = require('./traceReduxMiddleware')
const reduxSerialEffects = require('redux-serial-effects')
const rootReducer = require('./rootReducer')

const configureStore = (logger, datasetId) => {
  const {
    middleware: serialEffectsMiddleware,
    subscribe,
    onIdle
  } = reduxSerialEffects.createMiddleware()

  const store = createReduxStore(
    rootReducer,
    undefined,
    applyMiddleware(
      serialEffectsMiddleware,
      createTraceReduxMiddleware(logger, datasetId)
    )
  )

  return {
    store,
    subscribe,
    onIdle
  }
}

module.exports = configureStore
