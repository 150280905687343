'use strict'

const { union } = require('@wix/wix-code-adt')

const TraceType = union('TraceType', {
  Breadcrumb: ({ category, message, level, data }) => ({
    category,
    message,
    level,
    data
  }),
  Action: ({ actionName, options }) => ({ actionName, options })
})

module.exports.TraceType = TraceType
