'use strict'

const viewerAutomationsClientCreator = require('./viewerAutomationsClientCreator')
const editorAutomationsClientCreator = require('./editorAutomationsClientCreator')
const generateAutomationsFieldName = require('./generateFieldName')

const isFieldSupported = require('./isFieldSupported')

module.exports = {
  viewerAutomationsClientCreator,
  editorAutomationsClientCreator,
  generateAutomationsFieldName,
  isFieldSupported
}
