'use strict'

const values_ = require('lodash/values')
const map_ = require('lodash/map')
const pickBy_ = require('lodash/pickBy')
const uniq_ = require('lodash/uniq')

const hasReferencedCollection = field => !!field.referencedCollection
const getReferencedCollection = field => field.referencedCollection

const _getReferencedCollectionsNames = fields => {
  const referendingFields = pickBy_(fields, hasReferencedCollection)
  const references = values_(referendingFields)
  const referencedCollections = map_(references, getReferencedCollection)
  const uniqueReferencedCollectionsNames = uniq_(referencedCollections)

  return uniqueReferencedCollectionsNames.filter(Boolean)
}

const getReferencedCollectionsNames = schema => {
  return schema.fields ? _getReferencedCollectionsNames(schema.fields) : null
}

module.exports = getReferencedCollectionsNames
