'use strict'

const get_ = require('lodash/get')
const pick_ = require('lodash/pick')
const defaultDatasetConfiguration = require('@wix/wix-data-client-common/src/dataset-configuration/defaults')
const readWriteModes = require('@wix/wix-data-client-common/src/dataset-configuration/readWriteModes')
const actionTypes = require('./actionTypes')
const recordActionTypes = require('../records/actionTypes')
const rootActions = require('../dataset-controller/actions')

const {
  DETAILS_DATASET_ROLE
} = require('@wix/wix-data-client-common/src/connection-config/roles')
const {
  ROUTER_DATASET
} = require('@wix/wix-data-client-common/src/datasetTypes')
const {
  WRITE
} = require('@wix/wix-data-client-common/src/dataset-configuration/readWriteModes')

const canonicalConfigProperties = [
  'collectionName',
  'readWriteType',
  'includes',
  'preloadData'
]
const transientConfigProperties = ['filter', 'sort', 'pageSize']

const getCanonicalConfig = datasetConfig =>
  pick_(datasetConfig, canonicalConfigProperties)
const getTransientConfig = datasetConfig =>
  pick_(datasetConfig, transientConfigProperties)
const getCanonicalCalculatedData = ({
  datasetConfig,
  connections,
  isScoped,
  datasetType
}) => {
  const datasetIsRouter = datasetType === ROUTER_DATASET
  const datasetIsMaster = connections.some(
    ({ role }) => role === DETAILS_DATASET_ROLE
  )
  const datasetIsVirtual = isScoped
  const datasetIsReal = !isScoped
  const datasetIsWriteOnly = datasetConfig.readWriteType === WRITE
  const datasetIsDeferred =
    Boolean(datasetConfig.deferred) &&
    !(
      datasetIsVirtual ||
      datasetIsMaster ||
      datasetIsRouter ||
      datasetIsWriteOnly
    )
  const dynamicPageNavComponentsShouldBeLinked =
    datasetIsRouter && datasetIsReal

  return {
    datasetIsRouter,
    datasetIsMaster,
    datasetIsVirtual,
    datasetIsReal,
    datasetIsDeferred,
    dynamicPageNavComponentsShouldBeLinked
  }
}

const assignTransientData = (state, transientData) => {
  return {
    ...state,

    transientData: {
      ...state.transientData,
      ...transientData
    }
  }
}

const initialState = {
  canonicalData: getCanonicalConfig(defaultDatasetConfiguration),
  canonicalCalculatedData: {},
  transientData: {
    isDatasetReady: false,
    allowWixDataAccess: defaultDatasetConfiguration.preloadData,
    ...getTransientConfig(defaultDatasetConfiguration)
  }
}

module.exports = (state = initialState, action) => {
  switch (action.type) {
    case rootActions.actionTypes.INIT: {
      const allowWixDataAccess = get_(
        action,
        ['datasetConfig', 'preloadData'],
        true
      )
      const { datasetConfig, connections, isScoped, datasetType } = action
      return {
        ...state,

        canonicalData: {
          ...state.canonicalData,
          ...getCanonicalConfig(action.datasetConfig)
        },

        canonicalCalculatedData: getCanonicalCalculatedData({
          datasetConfig,
          connections,
          isScoped,
          datasetType
        }),
        transientData: {
          ...state.transientData,
          ...getTransientConfig(action.datasetConfig),
          allowWixDataAccess
        }
      }
    }

    case actionTypes.SET_FILTER: {
      const { filter } = action

      return assignTransientData(state, { filter, allowWixDataAccess: true })
    }

    case actionTypes.SET_SORT: {
      const { sort } = action

      return assignTransientData(state, { sort })
    }

    case actionTypes.SET_IS_DATASET_READY: {
      const { isDatasetReady } = action

      return assignTransientData(state, { isDatasetReady })
    }

    case actionTypes.SET_FIXED_FILTER_ITEM: {
      const { fixedFilterItem } = action

      return assignTransientData(state, { fixedFilterItem })
    }

    case recordActionTypes.REFRESH: {
      return assignTransientData(state, { allowWixDataAccess: true })
    }

    default:
      return state
  }
}

module.exports.isWriteOnly = state =>
  get_(state, ['canonicalData', 'readWriteType']) === readWriteModes.WRITE

module.exports.isReadOnly = state =>
  get_(state, ['canonicalData', 'readWriteType']) === readWriteModes.READ

module.exports.getReadWriteMode = state =>
  get_(state, ['canonicalData', 'readWriteType'])

module.exports.isDatasetReady = state =>
  get_(state, ['transientData', 'isDatasetReady'])

module.exports.isDatasetConfigured = state =>
  !!get_(state, ['canonicalData', 'collectionName'])

module.exports.isDatasetRouter = ({
  canonicalCalculatedData: { datasetIsRouter }
}) => datasetIsRouter

module.exports.isDatasetMaster = ({
  canonicalCalculatedData: { datasetIsMaster }
}) => datasetIsMaster

module.exports.isDatasetVirtual = ({
  canonicalCalculatedData: { datasetIsVirtual }
}) => datasetIsVirtual

module.exports.isDatasetReal = ({
  canonicalCalculatedData: { datasetIsReal }
}) => datasetIsReal

module.exports.isDatasetDeferred = ({
  canonicalCalculatedData: { datasetIsDeferred }
}) => datasetIsDeferred

module.exports.shouldLinkDynamicPageNavComponents = ({
  canonicalCalculatedData: { dynamicPageNavComponentsShouldBeLinked }
}) => dynamicPageNavComponentsShouldBeLinked

module.exports.getPageSize = state => get_(state, ['transientData', 'pageSize'])

module.exports.getFixedFilterItem = state =>
  get_(state, ['transientData', 'fixedFilterItem'])

module.exports.getFilter = state => get_(state, ['transientData', 'filter'])

module.exports.getSort = state => get_(state, ['transientData', 'sort'])

module.exports.getIncludes = state =>
  get_(state, ['canonicalData', 'includes']) || []

module.exports.getCollectionName = state =>
  get_(state, ['canonicalData', 'collectionName'])

module.exports.getPreloadData = state =>
  get_(state, ['canonicalData', 'preloadData'], true)

module.exports.shouldAllowWixDataAccess = state =>
  get_(state, ['transientData', 'allowWixDataAccess'])
