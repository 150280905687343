'use strict'

const get_ = require('lodash/get')
const isObject_ = require('lodash/isObject')
const { Maybe } = require('@wix/wix-code-adt')

const {
  isFieldFromReferencedCollection,
  getReferenceFieldName,
  getFieldFromReferencedCollectionName
} = require('@wix/dbsm-common/src/reference-fields/fieldPath')

const getFieldValue = fieldName => record => get_(record, fieldName)

const getReference = fieldPath => record =>
  Maybe.fromNullable(get_(record, getReferenceFieldName(fieldPath)))

const getFieldFromReference = fieldPath => reference =>
  Maybe.fromNullable(reference)
    .chain(reference =>
      isObject_(reference) ? Maybe.Just(reference) : Maybe.Nothing()
    )
    .map(referencedRecord =>
      getFieldValue(getFieldFromReferencedCollectionName(fieldPath))(
        referencedRecord
      )
    )
    .getOrElse(undefined)

module.exports = (record, fieldPath) =>
  isFieldFromReferencedCollection(fieldPath)
    ? getReference(fieldPath)(record)
        .map(getFieldFromReference(fieldPath))
        .getOrElse(undefined)
    : getFieldValue(fieldPath)(record)
